<template>
<footer class="footer footer-static footer-light mt-1 noPrint">
    <p class="clearfix mb-0"><span class="float-left d-inline-block">{{ new Date().getFullYear() }} &copy; {{ settingDefaultCompanyName?.value ?? '' }}</span><span class="float-right d-sm-inline-block d-none">Crafted with<i class="bx bxs-heart pink mx-50 font-small-3"></i>by<a class="text-uppercase" href="#" target="_blank">Krazy IT</a></span></p>
    <!-- <p>{{currentTime}}</p> -->
</footer>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    name: 'Footer',
    computed: {
        ...mapGetters({
            settingDefaultCompanyName: 'appSettings/settingDefaultCompanyName',

        }),
    },
    methods: {
        ...mapActions({
            getSettings: "appSettings/getSettings",

        }),

        async getSettingList() {
            await this.getSettings({
                type: ["default"],
                key: [
                    "default_company_name",
                ],
            });
        },

    },
    mounted() {
        this.getSettingList();
    },
}
</script>
