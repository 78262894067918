<template>
<!-- BEGIN: Header-->
<nav class="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-static-top bg-primary navbar-brand-center noPrint">
    <div class="navbar-header d-xl-block d-none">
        <router-link :to="{name:'appDashboard'}">
            <img class="logo" :style="{height:'50px'}" :src="settingDefaultWebsiteLogoLite?.value ">
        </router-link>
    </div>
    <div class="navbar-wrapper">
        <div class="navbar-container content">
            <div class="navbar-collapse" id="navbar-mobile">
                <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                    <ul class="nav navbar-nav d-block d-xl-none" @click="mobileSideBar" :style="{cursor:['pointer']}">
                        <li class="nav-item mobile-menu mr-auto"><a class="nav-link nav-menu-main menu-toggle"><i class="bx bx-menu"></i></a></li>
                    </ul>
                    <!--
                    <ul class="nav navbar-nav bookmark-icons">
                        <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-email.html" data-toggle="tooltip" data-placement="top" title="Email"><i class="ficon bx bx-envelope"></i></a></li>
                        <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-chat.html" data-toggle="tooltip" data-placement="top" title="Chat"><i class="ficon bx bx-chat"></i></a></li>
                        <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-todo.html" data-toggle="tooltip" data-placement="top" title="Todo"><i class="ficon bx bx-check-circle"></i></a></li>
                        <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-calendar.html" data-toggle="tooltip" data-placement="top" title="Calendar"><i class="ficon bx bx-calendar-alt"></i></a></li>
                    </ul>
                    <ul class="nav navbar-nav">
                        <li class="nav-item d-none d-lg-block"><a class="nav-link bookmark-star"><i class="ficon bx bx-star warning"></i></a>
                            <div class="bookmark-input search-input">
                                <div class="bookmark-input-icon"><i class="bx bx-search primary"></i></div>
                                <input class="form-control input" type="text" placeholder="Explore Frest..." tabindex="0" data-search="template-search">
                                <ul class="search-list"></ul>
                            </div>
                        </li>
                    </ul> -->
                </div>
                <ul class="nav navbar-nav float-right d-flex align-items-center">
                    <!-- <li>
                        <router-link :to="{name:'techTracker'}">
                            <div class="d-flex align-items-center px-1">
                                <i class='bx bx-map text-white'></i>
                                <span class="text-white user-name">Tech Tracker</span>

                            </div>
                        </router-link>
                    </li> -->
                    <!-- <li class="dropdown dropdown-language nav-item"><a class="dropdown-toggle nav-link" id="dropdown-flag" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="flag-icon flag-icon-us"></i><span class="selected-language d-lg-inline d-none">English</span></a>
        <div class="dropdown-menu" aria-labelledby="dropdown-flag"><a class="dropdown-item" href="javascript:void(0);" data-language="en"><i class="flag-icon flag-icon-us mr-50"></i>English</a><a class="dropdown-item" href="javascript:void(0);" data-language="fr"><i class="flag-icon flag-icon-fr mr-50"></i>French</a><a class="dropdown-item" href="javascript:void(0);" data-language="de"><i class="flag-icon flag-icon-de mr-50"></i>German</a><a class="dropdown-item" href="javascript:void(0);" data-language="pt"><i class="flag-icon flag-icon-pt mr-50"></i>Portuguese</a></div>
    </li> -->
                    <!-- <li class="nav-item d-none d-lg-block"><a class="nav-link nav-link-expand"><i class="ficon bx bx-fullscreen"></i></a></li> -->
                    <!--                    <li class="nav-item nav-search"><a class="nav-link nav-link-search pt-2"><i class="ficon bx bx-search"></i></a>-->
                    <!--                        <div class="search-input">-->
                    <!--                            <div class="search-input-icon"><i class="bx bx-search primary"></i></div>-->
                    <!--                            <input class="input" type="text" placeholder="Explore Frest..." tabindex="-1" data-search="template-search">-->
                    <!--                            <div class="search-input-close"><i class="bx bx-x"></i></div>-->
                    <!--                            <ul class="search-list"></ul>-->
                    <!--                        </div>-->
                    <!--                    </li>-->

                    <!-- <li class=" dropdown-notification nav-item ">
                        <router-link :to="{name:'app.notification'}" class="nav-link nav-link-label px-1"><i class='bx bx-bell bx-tada'></i><span class="badge badge-pill badge-danger badge-up">5</span></router-link>

                    </li> -->
                    <!-- <li class="dropdown-menu-footer"><a class="dropdown-item p-50 text-primary justify-content-center" href="javascript:void(0);">Read all notifications</a></li> -->

                    <li class=" dropdown-notification nav-item ">
                        <span class="text-white">{{this.$store.getters["appAuthLogin/authUser"].firstName}} {{this.$store.getters["appAuthLogin/authUser"].lastName}}</span>
                    </li>
                    <li class="dropdown dropdown-user nav-item px-0"><a class="dropdown-toggle nav-link dropdown-user-link px-1" data-toggle="dropdown">
                            <div class="user-nav d-lg-flex d-none mr-0"></div>
                            <i style="cursor:pointer;" class="bx bx-user"></i>
                            <!-- <img class="round" :src="this.authUser.avatar" alt="avatar" height="40" width="40"> -->
                            <!-- <span class="user-name">{{ this.authUser.lastName }}</span><span class="user-status">{{ this.authUser.role }}</span> -->
                        </a>
                        <div class="dropdown-menu dropdown-menu-right pb-0">
                            <!-- <a class="dropdown-item " href="page-user-profile.html"><i class="bx bx-user mr-50"></i> Edit Profile</a>
                            <a class="dropdown-item" href="app-email.html"><i class="bx bx-envelope mr-50"></i> My Inbox</a>
                            <a class="dropdown-item" href="app-todo.html"><i class="bx bx-check-square mr-50"></i> Task</a>
                            <a class="dropdown-item" href="app-chat.html"><i class="bx bx-message mr-50"></i> Chats</a> -->
                            <div class="mb-0" style="cursor:pointer;"></div><a style="cursor:pointer;" class="dropdown-item" @click="submitLogout"><i class="bx bx-power-off mr-50"></i> Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- END: Header-->

<div class="d-block d-lg-block d-xl-none ">
    <div @click="closeMobileSideBar" :class="[mobileSideBarIsActive ? 'mobile-sidebar-overlay' : '']">

    </div>
    <div>
        <div class="py-2" :class="[mobileSideBarIsActive ? 'sidebar-menu ' : 'sidebar-menu sidebar-menu-hide ']">
            <div class="d-flex justify-content-between align-items-center side-bar-image-section">
                <div>
                    <img :src="settingDefaultWebsiteLogoRegular?.value" class="admin-panel-logo" alt="">
                </div>
                <div @click="closeMobileSideBar" class="cross-icon-mobile-sidebar">
                    <i class='bx bx-x'></i>
                </div>
            </div>

            <div id="accordion" class="side-bar-nav-content-section">
                <div class="side-bar-nav-content-content-with-icon ">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#inventory">
                        <div class="d-flex align-items-center"><i class='bx bxs-dashboard'></i> <span class="pl-1 side-bar-nav-content">Inventory</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="inventory" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div class="side-nav-child-content-with-icon-margin-padding" v-if="canAny(['warehouse-view-any','warehouse-create'])">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#warehouse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Warehouse</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="warehouse" class="collapse " data-parent="#inventory">
                                    <div v-if="can('warehouse-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appWarehouseList'}">All Warehouse</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('warehouse-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appWarehouseCreate'}">Add Warehouse</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="canAny(['product-item-view-any','product-item-create'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#products">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Product Item</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="products" class="collapse " data-parent="#inventory">
                                    <div v-if="can('product-item-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appProductItemList'}">All Product Item</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('product-item-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appProductItemCreate'}">Add Product Item</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="canAny(['product-item-tracking-view-any'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#product-item-track">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Product Item Track</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="product-item-track" class="collapse " data-parent="#inventory">
                                    <div v-if="can('product-item-tracking-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appProductItemTracking'}">All Product Item Track List</router-link>
                                            </span> </div>
                                    </div>

                                </div>
                            </div>
                            <!-- <div v-if="can('product-item-tracking-view-any')" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">
                                            <router-link class="nav-item" :to="{name:'appProductItemTracking'}"> Hardware Tracking</router-link>
                                        </span> </div>
                                </div>
                            </div> -->
                        </div>

                    </div>

                </div>
                <div class="side-bar-nav-content-content-with-icon " v-if="canAny([
                 'appointment-view-any',
                 'appointment-create',
                 'appointment-customer-list-export',
                 'order-view-any',
                 'order-create',])">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#business">
                        <div class="d-flex align-items-center"><i class='bx bxs-business'></i> <span class="pl-1 side-bar-nav-content">Business</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="business" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div  v-if="canAny(['appointment-view-any','appointment-create', 'appointment-customer-list-export'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#appointment">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Appointments</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="appointment" class="collapse " data-parent="#business">
                                    <div v-if="can('appointment-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appAppointmentList'}">All Appointment</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('appointment-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appAppointmentCreate'}">Add Appointment</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('appointment-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appAppointmentReport'}">Appointment Report</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('appointment-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appAppointmentCostEstimate'}">Cost Estimate</router-link>
                                            </span> </div>
                                    </div>
                                  <div v-if="can('appointment-customer-list-export')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appAppointmentExportsCustomers'}">Export Customers</router-link>
                                            </span> </div>
                                  </div>
                                </div>
                            </div>
                            <div  v-if="canAny(['call-request-create','call-request-view-any'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#callRequest">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Call Request</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="callRequest" class="collapse " data-parent="#business">
                                    <div v-if="can('call-request-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appCallRequestList'}">All Call Request</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                                <div id="callRequest" class="collapse " data-parent="#business">
                                    <div v-if="can('call-request-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appCallRequestCreate'}">Call Request Create</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div  v-if="canAny(['appointment-consent-view-any',])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#appointmentCloser">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Appointment Closers</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="appointmentCloser" class="collapse " data-parent="#business">
                                    <div v-if="can('appointment-consent-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appAppointmentClosers'}">All Appointment Closer</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div  v-if="canAny(['appointment-consent-view-any',])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#appointemntServiceAgrements">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Service Agrements</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="appointemntServiceAgrements" class="collapse " data-parent="#business">
                                    <div v-if="can('appointment-consent-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appointmentServiceAgreements'}">All Service Agrement</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="canAny(['order-view-any','order-create'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#orders">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Orders</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="orders" class="collapse " data-parent="#business">
                                    <div v-if="can('order-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appOrderList'}">All Order</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('order-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appOrderCreate'}">Add New Order</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="canAny(['coupon-view-any','coupon-create','coupon-usage-view-any'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#coupon">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Coupon</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="coupon" class="collapse " data-parent="#business">
                                    <div  v-if="can('coupon-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appCouponList'}">All Coupon</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('coupon-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appCouponCreate'}">Add New Coupon</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('coupon-usage-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appCouponUsageView'}">Coupon usages</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>


                <div class="side-bar-nav-content-content-with-icon " v-if="canAny(['service-category-view-any','service-view-any', 'service-create','service-tip-view-any','product-category-view-any', 'product-category-create','product-view-any', 'product-create'])">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#productAndServices">
                        <div class="d-flex align-items-center"><i class='bx bxs-data'></i> <span class="pl-1 side-bar-nav-content">Products & Services</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="productAndServices" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div class="side-nav-child-content-with-icon-margin-padding"  v-if="canAny(['service-category-view-any'])">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#serviceCategory">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Service Categories</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="serviceCategory" class="collapse " data-parent="#productAndServices">
                                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">  <router-link class="nav-item" :to="{name:'appServiceCategoryList'}"> All</router-link></span> </div>
                                    </div>

                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding" v-if="canAny(['service-view-any', 'service-create'])">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#services">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Services</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="services" class="collapse " data-parent="#productAndServices">
                                    <div v-if="can('service-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class=""><router-link class="nav-item" :to="{name:'appServiceList'}"> All Service</router-link></span> </div>
                                    </div>
                                    <div v-if="can('service-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class=""><router-link class="nav-item" :to="{name:'appServiceCreate'}"> Add Service</router-link></span> </div>
                                    </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding" v-if="canAny(['service-tip-view-any'])">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#serviceTips">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Service Tips</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="serviceTips" class="collapse " data-parent="#productAndServices">
                                    <div v-if="canAny(['service-tip-view-any'])" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class=""><router-link class="nav-item" :to="{name:'appServiceTipList'}"> All Service Tip</router-link></span> </div>
                                    </div>

                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding" v-if="canAny(['product-category-view-any', 'product-category-create'])">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#productCategories">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Product Categories</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="productCategories" class="collapse " data-parent="#productAndServices">
                                    <div v-if="can('product-category-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class=""><router-link class="nav-item" :to="{name:'appProductCategoryList'}"> All </router-link></span> </div>
                                    </div>
                                    <div v-if="can('product-category-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class=""><router-link class="nav-item" :to="{name:'appProductCategoryCreate'}"> Add New</router-link></span> </div>
                                    </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding" v-if="canAny(['product-view-any','product-create'])">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#products">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Products</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="products" class="collapse " data-parent="#productAndServices">
                                    <div v-if="can('product-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class=""><router-link class="nav-item" :to="{name:'appProductList'}"> All </router-link></span> </div>
                                    </div>
                                    <div v-if="can('product-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class=""><router-link class="nav-item" :to="{name:'appProductCreate'}"> Add New</router-link></span> </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <div class="side-bar-nav-content-content-with-icon "  v-if="canAny(['admin-view-any', 'admin-create','customer-view-any', 'customer-create','technician-view-any', 'technician-create','team-lead-view-any','on-site-technician-view-any','remote-technician-view-any','manager-view-any', 'manager-create','store-manager-view-any','customer-care-view-any', 'customer-care-create','lab-user-view-any', 'lab-user-create','vendor-view-any', 'vendor-create','franchisee-view-any', 'franchisee-create',])">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#users">
                        <div class="d-flex align-items-center"><i class='bx bxs-user'></i> <span class="pl-1 side-bar-nav-content">Users</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="users" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div v-if="canAny(['franchisee-view-any', 'franchisee-create',])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#franchisees">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Franchisees</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="franchisees" class="collapse " data-parent="#users">
                                    <div v-if="can('franchisee-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appFranchiseeList'}">All Franchisee</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('franchisee-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appFranchiseeCreate'}">Add New Franchisee</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="canAny(['customer-view-any', 'customer-create'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#customers">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Customers</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="customers" class="collapse " data-parent="#users">
                                    <div v-if="can('customer-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appCustomerList'}">All Customer</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('customer-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appCustomerCreate'}">Add New Customer</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="canAny(['technician-view-any', 'technician-create'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#technicians">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Technicians</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="technicians" class="collapse " data-parent="#users">
                                    <div v-if="can('technician-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appTechnicianList'}">All Technician</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('technician-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appTechnicianCreate'}">Add New Technician</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>

                             <div v-if="canAny(['team-lead-view-any'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#teamLead">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Team Lead</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="teamLead" class="collapse " data-parent="#users">
                                    <div v-if="can('team-lead-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appTeamLeadList'}">All Team Lead</router-link>
                                            </span> </div>
                                    </div>

                                </div>
                            </div>

                            <div v-if="canAny(['on-site-technician-view-any'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#onSiteTechnicians">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">On-Site Technicians</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="onSiteTechnicians" class="collapse " data-parent="#users">
                                    <div v-if="can('on-site-technician-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appOnSiteTechnicianList'}">All On-Site Technician</router-link>
                                            </span> </div>
                                    </div>

                                </div>
                            </div>
                            <div v-if="canAny(['remote-technician-view-any'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#remoteTechnicians">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Remote Technicians</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="remoteTechnicians" class="collapse " data-parent="#users">
                                    <div v-if="can('remote-technician-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appRemoteTechnicianList'}">All >Remote Technician</router-link>
                                            </span> </div>
                                    </div>

                                </div>
                            </div>


                            <div v-if="canAny(['admin-view-any'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#superAdmin">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Super Admin</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="superAdmin" class="collapse " data-parent="#users">
                                    <div v-if="can('admin-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appSuperAdminList'}">All Super Admin</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="canAny(['admin-view-any','admin-create'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#admins">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Admins</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="admins" class="collapse " data-parent="#users">
                                    <div v-if="can('admin-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appAdminList'}">All Admin</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('admin-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appAdminCreate'}">Add New Admin</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>


                            <div v-if="canAny(['manager-view-any', 'manager-create'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#managers">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Managers</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="managers" class="collapse " data-parent="#users">
                                    <div v-if="can('manager-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appManagerList'}">All Manager</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('manager-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appManagerCreate'}">Add New Manager</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="canAny(['store-manager-view-any'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#storeManager">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Store Managers</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="storeManager" class="collapse " data-parent="#users">
                                    <div v-if="can('store-manager-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appStoreManagerList'}">All Store Manager</router-link>
                                            </span> </div>
                                    </div>

                                </div>
                            </div>

                            <div v-if="canAny(['customer-care-view-any', 'customer-care-create'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#customerCare">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Customer Care</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="customerCare" class="collapse " data-parent="#users">
                                    <div v-if="can('customer-care-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appCustomerCareList'}">All Customer Care</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('customer-care-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appCustomerCareCreate'}">Add New Customer Care</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="canAny(['lab-user-view-any', 'lab-user-create'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#labUser">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Lab Users</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="labUser" class="collapse " data-parent="#users">
                                    <div v-if="can('lab-user-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appLabUserList'}">All Lab User</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('lab-user-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appLabUserCreate'}">Add New Lab User</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>


                            <div v-if="canAny(['vendor-view-any', 'vendor-create'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#vendor">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Vendors</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="vendor" class="collapse " data-parent="#users">
                                    <div v-if="can('vendor-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appVendorList'}">All Vendor</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('vendor-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appVendorCreate'}">Add New Vendor</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>




                <div class="side-bar-nav-content-content-with-icon "  v-if="canAny(['payment-view-any', 'cash-payment-view-any'])">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#account-and-finance">
                        <div class="d-flex align-items-center"><i class='bx bxs-dollar-circle'></i> <span class="pl-1 side-bar-nav-content">Account & Finance</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="account-and-finance" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div v-if="canAny(['payment-view-any'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#roles">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Payments</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="roles" class="collapse " data-parent="#account-and-finance">
                                    <div v-if="can('role-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appAccountFinancePaymentsList'}">All Payments </router-link>
                                            </span> </div>
                                    </div>

                                </div>
                            </div>

                            <div v-if="canAny(['cash-payment-view-any'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#cashTrack">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Cash Track</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="cashTrack" class="collapse " data-parent="#account-and-finance">
                                    <div v-if="can('cash-payment-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appAccountFinancePaymentCashCollections'}">All Cash Track List</router-link>
                                            </span> </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>





                <div class="side-bar-nav-content-content-with-icon " v-if="canAny([
                'setting-notification-view-any',
                'setting-default-update',
                'setting-customer-update',
                'setting-service-update',
                'setting-technician-update',
                'setting-product-update',
                'setting-contact-update'])">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#settings">
                        <div class="d-flex align-items-center"><i class='bx bxs-cog icon-right-spacing'></i> <span class="pl-1 side-bar-nav-content">Settings</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="settings" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div  v-if="canAny(['setting-customer-update','setting-technician-update','setting-vendor-update'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#settingUsers">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Users</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="settingUsers" v-if="can('setting-customer-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'settingsCustomerList'}">Customers</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                                <div id="settingUsers" v-if="can('setting-technician-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'settingsTechnician'}">Technician</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                                <div id="settingUsers" v-if="can('setting-vendor-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'settingVendorList'}">Vendor</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                                <div id="settingUsers" v-if="can('setting-franchisee-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'franchiseeSettingList'}">Franchisee</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>

                            <div  v-if="canAny(['setting-appointment-update','setting-order-update'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#settingBusiness">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Business</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
<!--                                 content level 2-->
                                <div id="settingBusiness" v-if="can('setting-appointment-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'settingsAppointmentList'}">Appointment</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                                <div id="settingBusiness" v-if="can('setting-order-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'settingsOrderList'}">Order</router-link>
                                            </span> </div>
                                    </div>
                                </div>

                            </div>
                            <div  v-if="canAny(['setting-payment-update','setting-technician-commission-payment-update','setting-franchisee-payment-update'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#settingAccountAndFinance">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Accounts & Finances </span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="settingAccountAndFinance" v-if="can('setting-payment-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'PaymentSetting'}">Payment</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                                <div id="settingAccountAndFinance" v-if="can('setting-technician-commission-payment-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'TechnicianCommissionPayment'}">Technician Commission Payment</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                                <div id="settingAccountAndFinance" v-if="can('setting-franchisee-payment-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'franchiseePaymentSetting'}">Franchisee Payment</router-link>
                                            </span> </div>
                                    </div>
                                </div>

                            </div>
                            <div  v-if="canAny(['setting-service-update','setting-product-update'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#settingProductAndServices">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Product & Service </span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="settingProductAndServices" v-if="can('setting-product-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'settingsProductList'}">Product</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                                <div id="settingProductAndServices" v-if="can('setting-service-update')" class="collapse " data-parent="#settings">
                                    <div  class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'settingsServiceList'}">Service</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>


                            <div  v-if="canAny(['setting-contact-update'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div v-if="can('setting-contact-update')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" >
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">  <router-link class="nav-item" :to="{name:'settingsContactList'}">Contact</router-link> </span> </div>
                                </div>
                            </div>

                             <div  v-if="canAny(['setting-notification-view-any'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div v-if="can('setting-notification-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" >
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">  <router-link class="nav-item" :to="{name:'settingsNotificationList'}">Notification</router-link> </span> </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>



                <div class="side-bar-nav-content-content-with-icon "  v-if="canAny(['role-view-any', 'role-create','vacancy-view-any', 'vacancy-create'])">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#others">
                        <div class="d-flex align-items-center"><i class='bx bxs-extension'></i> <span class="pl-1 side-bar-nav-content">Others</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="others" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div v-if="canAny(['role-view-any', 'role-create'])"  class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#roles">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Roles</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="roles" class="collapse " data-parent="#others">
                                    <div v-if="can('role-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appRoleList'}">All </router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('role-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item d-block" :to="{name:'appRoleCreate'}">Add New</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="canAny(['vacancy-view-any', 'vacancy-create'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#vacancy">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Vacancies</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="vacancy" class="collapse " data-parent="#others">
                                    <div v-if="can('vacancy-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appVacancyList'}">All Vacancy</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('vacancy-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appVacancyCreate'}">Add New Vacancy</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="canAny(['blog-view-any', 'blog-create'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#blogs">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Blogs</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="blogs" class="collapse " data-parent="#others">
                                    <div v-if="can('blog-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appBlogList'}">All Blog</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('blog-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appBlogCreate'}">Add New Blog</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="canAny(['blog-category-view-any', 'blog-category-create'])" class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#blogCategory">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Blog Categories</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>
                                <!-- content level 2-->
                                <div id="blogCategory" class="collapse " data-parent="#others">
                                    <div v-if="can('blog-category-view-any')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appBlogCategoryList'}">All Blog Categories</router-link>
                                            </span> </div>
                                    </div>
                                    <div v-if="can('blog-category-create')" class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">
                                                <router-link class="nav-item" :to="{name:'appBlogCategoryCreate'}">Add New Blog Category</router-link>
                                            </span> </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <!-- <div class="side-bar-nav-content-content-with-icon ">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#collapseTwo">
                        <div class="d-flex align-items-center"><i class='bx bxs-business'></i> <span class="pl-1 side-bar-nav-content">Products & Services</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="collapseTwo" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Services</span> </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Inventory</span> </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->

                <!-- <div class="side-bar-nav-content-content-with-icon ">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#collapseThree">
                        <div class="d-flex align-items-center"><i class='bx bxs-business'></i> <span class="pl-1 side-bar-nav-content">Users</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="collapseThree" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Customers</span> </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Geeks</span> </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Customer Care Staffs </span> </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Managers </span> </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Admins </span> </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Account & Finance </span> </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Partners </span> </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Vendors </span> </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->





                <!-- <div class="side-bar-nav-content-content-with-icon ">
                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div" data-toggle="collapse" data-target="#collapsFive">
                        <div class="d-flex align-items-center"><i class='bx bxs-business'></i> <span class="pl-1 side-bar-nav-content">Marketing</span> </div>
                        <div><i class="chevron fa fa-fw"></i></div>
                    </div>
                    <div id="collapsFive" class="collapse " data-parent="#accordion">
                        <div class="collapse-content">
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class=""> Emails </span> </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class=""> Promotions </span> </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class=""> Cupons </span> </div>
                                </div>
                            </div>
                            <div class="side-nav-child-content-with-icon-margin-padding">
                                <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse" data-target="#ReferralPoints">
                                    <div class="d-flex align-items-center"><i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="">Referral points</span> </div>
                                    <div><i class="chevron fa fa-fw"></i></div>
                                </div>

                                <div id="ReferralPoints" class="collapse " data-parent="#collapsFive">
                                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">Income</span> </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">Expenses</span> </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between panel-heading collapsed side-nav-bar-content-div side-nav-child-padding-y" data-toggle="collapse">
                                        <div class="d-flex align-items-center"><i class="nav-arrow-right-icon" style="margin-left: 16px;"></i> <span class="">Close</span> </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div> -->

            </div>
        </div>
    </div>

</div>
</template>

<script>
import Authorization from "@/components/backEnd/mixins/Authorization";
import {
    mapGetters,
    mapActions
} from 'vuex';

export default {
    name: "TopNav",
    mixins: [Authorization],
    data() {
        return {
            mobileSideBarIsActive: false,
            responseMessage: {
                message: '',
                type: 'default',
            },
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'appAuthLogin/authUser',
            settingDefaultWebsiteLogoLite: 'appSettings/settingDefaultWebsiteLogoLite',
            settingDefaultWebsiteLogoRegular: 'appSettings/settingDefaultWebsiteLogoRegular',
        }),
    },
    methods: {
        ...mapActions({
            logout: 'appAuthLogout/logout',
            getSettings: "appSettings/getSettings",
        }),
        async submitLogout() {
            await this.logout()
                .then((response) => {
                    if (response.status === 204 || response.status === 401 || response.status === 419) {
                        this.$router.replace({
                            name: 'appLogin',
                        });
                    }
                    if (response.message) {
                        this.responseMessage.message = response.message;
                        this.responseMessage.type = response.type;
                        this.$toast.open({
                            message: this.responseMessage.message,
                            type: this.responseMessage.type,
                            position: 'bottom-right',
                            duration: 5000,
                            dismissible: true,
                        });
                    }
                })
        },
        mobileSideBar() {

            this.mobileSideBarIsActive = !this.mobileSideBarIsActive;
        },
        closeMobileSideBar() {
            this.mobileSideBarIsActive = false;
        },
        async getSettingList() {
            await this.getSettings({
                type: ["default"],
                key: [
                    "default_website_logo_lite","default_website_logo_regular",
                ],
            });
        },
    },
    mounted() {
        this.getSettingList();
    },
}
</script>

<style scoped>
.nav-item {
    color: #727E8C;
}

/* mobile side bar css */
.mobile-sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    z-index: 997;
    height: 100vh;
    transition: all 0.5s;
    overflow: hidden;

}

.sidebar-menu {
    position: fixed;
    top: 0;
    left: 0px;
    width: 300px;
    background-color: #f2f4f4;
    opacity: 300;
    height: 100%;
    transition: all .3s ease-in-out;
    z-index: 99999;
    overflow-y: scroll;
}

.sidebar-menu-hide {
    left: -100%;

}

.admin-panel-logo {
    /* height: 100px; */
    width: 100px;
    object-fit: contain;
}

.cross-icon-mobile-sidebar>i {
    color: #5A8DEE !important;
    font-size: 1.4rem !important;
    cursor: pointer;
}

.panel-heading .chevron:after {
    font-family: "fontawesome";
    content: "\f106";
    font-weight: 300;
    color: #8494A7;
    font-size: 18px;

}

.panel-heading.collapsed .chevron:after {
    font-family: "fontawesome";
    content: "\f107";
    font-weight: 300;
    color: #8494A7;
    font-size: 18px;

}

.side-bar-image-section {
    padding: .35rem 1.45rem .3rem 1.3rem;
}

.side-bar-nav-content-section {
    margin: 1rem;
}

.side-bar-nav-content {
    color: #8494A5 !important;
    font-size: 1rem;
}

.side-bar-nav-content-content-with-icon {
    padding: 10px 0.5rem 10px 12px;
    border-bottom: 1px solid #DFE3E7;
    /* background-color: white; */
}

.nav-arrow-right-icon {
    color: #8494A7;
}

.side-nav-bar-content-div {
    cursor: pointer;
}

.side-nav-child-content-with-icon-margin-padding {
    padding: 0px 0px 0px 9px !important;
    margin: 0px 0px 0px 11px;

}

.side-nav-child-padding-y {
    padding-top: 6px;
    padding-bottom: 6px;
}

/* top nav css */
.navbar-header {
    position: absolute;
    left: 50% !important;
    padding: 0;
    transform: translateX(-50%) !important;

}

.collapse-content {
    margin: 8px 0px;
    background: white !important;
    transition: all 0.3s ease-in-out;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

/* .side-bar-nav-content-content-with-icon .collapsed{
  background-color: #5A8DEE !important;
} */

.navbar-header img {
    /* height: 50px;
  width: 50px; */
}
</style>
