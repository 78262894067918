<template>
<div>
   
    <div class="horizontal-layout horizontal-menu navbar-static 2-columns footer-static" data-open="hover" data-menu="horizontal-menu" data-col="2-columns">

        <TopNav></TopNav>

        <Menu></Menu>

        <div class="app-content content ">
            <div class="content-overlay"></div>
            <div class="content-wrapper py-0">
                <div class="content-header row">
                </div>
                <div class="content-body">
                    <slot name="appContent">

                    </slot>

                </div>
                <Footer/>
            </div>

        </div>

    </div>
</div>
</template>

<script>

import Menu from '@/components/backEnd/menu/Menu.vue';
import TopNav from '@/components/backEnd/topNav/TopNav.vue';
import Footer from '@/components/backEnd/footer/Footer.vue';
export default {
    name: "AppLayout",
    data() {
        return {
            // currentTime:'',
        }
    },
    components: {
    Menu,
    TopNav,
    Footer
},
    mounted() {
        // setInterval(() => {
        //     this.currentTime = `${new Date().toLocaleString()} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
        // }, 1000);
    },
}
</script>

<style scoped>
.fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f2f4f4;
    z-index: 10;
    color: #727E8C;
}
</style>
